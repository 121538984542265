import { ContainerModuleLoader, withDependencies } from '@wix/thunderbolt-ioc'
import { SiteMembersApi } from './siteMembersApi'
import { SiteMembersApiSymbol } from './symbols'
import { SiteMembersSiteConfig } from './types'
import { TpaHandlerProviderSymbol, LifeCycle, IPageDidUnmountHandler } from '@wix/thunderbolt-symbols'
import { SiteMembersTPAHandlers } from './tpaHandlers'

export const site: ContainerModuleLoader = (bind, bindAll) => {
	bindAll([SiteMembersApiSymbol, LifeCycle.AppWillMountHandler], SiteMembersApi)
}

export const page: ContainerModuleLoader = (bind) => {
	bind(TpaHandlerProviderSymbol).to(SiteMembersTPAHandlers)
	bind(LifeCycle.PageDidUnmountHandler).to(
		// This is in order to get the same instance that was created above
		withDependencies([SiteMembersApiSymbol], (siteMembersApi: IPageDidUnmountHandler) => ({
			pageDidUnmount() {
				siteMembersApi.pageDidUnmount()
			},
		}))
	)
}

export { SiteMembersApiSymbol, SiteMembersSiteConfig }
export { BIEvents } from './biEvents'
export { PrivacyStatus } from './constants'
export * from './types'
